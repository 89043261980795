.br-theme-css-stars .br-widget {
  height: 28px;
  white-space: nowrap;
}
.br-theme-css-stars .br-widget a {
  text-decoration: none;
  height: 20px;
  width: 20px;
  float: left;
  font-size: 27px;
  margin-right: 5px;
}
.br-theme-css-stars .br-widget a:after {
  content: "\2605";
  color: #d2d2d2;
}
.br-theme-css-stars .br-widget a.br-active:after {
  color: #f4be00;
}
.br-theme-css-stars .br-widget a.br-selected:after {
  color: #f4be00;
}
.br-theme-css-stars .br-widget .br-current-rating {
  display: none;
}
.br-theme-css-stars .br-readonly a {
  cursor: default;
}
@media print {
  .br-theme-css-stars .br-widget a:after {
    content: "\2606";
    color: black;
  }
  .br-theme-css-stars .br-widget a.br-active:after,
  .br-theme-css-stars .br-widget a.br-selected:after {
    content: "\2605";
    color: black;
  }
}
